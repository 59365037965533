import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system, propTypes } from './lib/system'

const Longform = styled.div`
  > h2,
  > h3,
  > h4 {
    margin-top: ${themeGet('space.xl')};
    position: relative;
    z-index: 0;

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      margin-top: ${themeGet('space.xxl')};
    }

    &:active,
    &:focus,
    &:hover {
      .headerLink {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  > p,
  > ul,
  > ol {
    margin-bottom: ${themeGet('space.l')};
  }

  > blockquote,
  img,
  .gatsby-resp-image-wrapper,
  pre {
    margin-bottom: ${themeGet('space.xl')};
    margin-top: ${themeGet('space.xl')};
  }

  > p:first-child {
    font-size: ${themeGet('fontSizes.base')};

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.l')};
    }
  }

  > ul,
  > ol {
    margin-left: calc(${themeGet('space.l')} + ${themeGet('space.xs')});

    > li:not(:last-child) {
      margin-bottom: ${themeGet('space.base')};
    }
  }

  pre {
    border-radius: ${themeGet('radii.base')};
    margin-left: -${themeGet('space.xxl')};
    margin-right: -${themeGet('space.xxl')};
    padding: ${themeGet('space.xl')};
    padding-left: ${themeGet('space.xxl')};

    > code {
      font-size: ${themeGet('fontSizes.s')};
    }
  }

  > blockquote {
    font-size: ${themeGet('fontSizes.base')};
    padding-left: ${themeGet('space.base')};
    padding-right: ${themeGet('space.base')};
    position: relative;

    &::before {
      color: ${themeGet('colors.light')};
      content: open-quote;
      display: block;
      font-family: ${themeGet('fonts.heading')};
      font-size: 7rem;
      left: calc(${themeGet('space.xl')} - ${themeGet('space.xl')});
      position: absolute;
      top: calc(-${themeGet('space.l')} - ${themeGet('space.base')});
      z-index: -1;
    }

    &::after {
      content: close-quote;
      visibility: hidden;
    }

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.l')};
      padding-left: ${themeGet('space.xxl')};
      padding-right: ${themeGet('space.xxl')};

      &::before {
        left: calc(${themeGet('space.xxl')} - ${themeGet('space.l')});
        top: calc(-${themeGet('space.l')} - ${themeGet('space.base')});
      }
    }
  }

  .headerLink {
    left: -${themeGet('space.l')};
    opacity: 0;
    position: absolute;
    transition: 0.3s ease-in-out;
    visibility: hidden;
  }

  ${system}
`

Longform.propTypes = {
  ...propTypes,
}

export default Longform
