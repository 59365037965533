import React from 'react'

import avatar from '../images/avatar.jpg'
import Box from '../styled/Box'

function Avatar(props = {}) {
  return (
    <Box
      as="img"
      src={avatar}
      alt="Drew Barontini"
      height="200px"
      width="200px"
      borderRadius="50%"
      {...props}
    />
  )
}

Avatar.propTypes = {
  ...Box.propTypes,
}

export default Avatar
