import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'
import get from 'lodash/get'
import 'prism-theme-night-owl'

import AboutBlurb from '../components/AboutBlurb'
import Box from '../styled/Box'
import FancyHeader from '../components/FancyHeader'
import Layout from '../components/Layout'
import Longform from '../styled/Longform'
import PostDate from '../styled/PostDate'
import SEO from '../components/SEO'
import Tags from '../components/Tags'
import TwitterShare from '../components/TwitterShare'

function PostTemplate(props = {}) {
  const { mdx } = props.data
  const { frontmatter, body } = mdx
  const hasTags =
    get(frontmatter, 'tags') && get(frontmatter, 'tags').length > 0

  return (
    <Layout maxWidth="46.875rem">
      <SEO title={frontmatter.title} />
      {frontmatter.banner && (
        <Box mb={{ _: 'xl', md: 'xxl' }}>
          <Box
            as={Img}
            fluid={frontmatter.banner.childImageSharp.fluid}
            borderRadius="base"
          />
        </Box>
      )}
      <Box as={FancyHeader} mb="l">
        <Box as="h1" lineHeight="1">
          {frontmatter.title}
        </Box>
        <PostDate>{frontmatter.date}</PostDate>
      </Box>
      <Longform mb={{ _: 'xl', md: 'xxl' }}>
        <MDXRenderer>{body}</MDXRenderer>
      </Longform>
      <TwitterShare path={frontmatter.path} title={frontmatter.title} />
      {hasTags && <Tags tags={frontmatter.tags} />}
      <AboutBlurb />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        banner {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
  }
`

export default PostTemplate
