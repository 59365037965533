import React from 'react'

import Avatar from '../components/Avatar'
import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'

function AboutBlurb(props = {}) {
  return (
    <FlexGrid
      alignItems="center"
      borderTop="1px solid"
      borderColor="border"
      color="border"
      display={{ _: 'block', md: 'flex' }}
      gutter="l"
      mt="l"
      pt="l"
    >
      <FlexGrid.Item mb={{ _: 'base', md: '0' }}>
        <Avatar height="120px" width="120px" mx={{ _: 'auto', md: '0' }} />
      </FlexGrid.Item>
      <FlexGrid.Item flex="1">
        <Box as="p" color="fg" fontSize="s">
          <Box as="strong">Drew Barontini</Box> is the Product Director at{' '}
          <Box as="a" href="https://differential.com">
            Differential
          </Box>
          . He solves problems and delivers effective solutions with design,
          programming, and productivity systems.
        </Box>
      </FlexGrid.Item>
    </FlexGrid>
  )
}

export default AboutBlurb
