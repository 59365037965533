import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const Card = styled.div`
  background-color: ${themeGet('colors.light')};
  border-radius: ${themeGet('radii.base')};
  padding: ${themeGet('space.l')};

  ${system}
`

export default Card
