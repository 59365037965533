import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Box from '../styled/Box'
import Card from '../styled/Card'

function TwitterShare(props = {}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            author
          }
        }
      }
    `
  )

  function handleShare(event) {
    event.preventDefault()
    const url = `${site.siteMetadata.siteUrl}/${props.path}`
    const text = `"${props.title}" by ${site.siteMetadata.author}`
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(text)}`
    )
  }

  return (
    <Card fontSize="s" mb="l" textAlign="center">
      <Box as="h4" mb="s">
        Share this article
      </Box>
      <Box as="p" mb="s">
        If you found this article interesting, please consider sharing it on
        Twitter{' '}
        <span role="img" aria-label="thank you">
          🙏
        </span>
      </Box>
      <Box as="a" href="#" onClick={handleShare}>
        Share on Twitter
      </Box>
    </Card>
  )
}

TwitterShare.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default TwitterShare
